<template>
  <div class="wordscard-container layout-border">
    <WordImgGroupCards
      :wordCardList="wordCardList"
      :titleInfo="titleInfo"
      @changeTheImg="changeTheImg"
      :buttonList="buttonList"
    />
  </div>
</template>
<script>
import WordImgGroupCards from "@/components/Course/CoursePage/WordImgGroupCards";

export default {
  components: {
    WordImgGroupCards,
  },
  data() {
    return {
      titleInfo:{
        hanzi:"有/没有",
        pinyin:"yǒu / méiyǒu"
      },
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G62-vocab-cards/lesson-38/menu-1.svg"),
          isCircle: true,
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G62-vocab-cards/lesson-38/menu-3.svg"),
          isCircle: true,
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G62-vocab-cards/lesson-38/menu-2.svg"),
          isCircle: true,
          startNumberRange: 3,
          endNumberRange: 3,
        },
      ],
      wordCardList: [
        [
          {
            pinyin: "Zhèr yǒu niǎo.",
            hanzi: "这儿有鸟。",
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-38/flamingo-1.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-38/flamingo-2-you.svg`),
            Showing:false,
          },
          {
            pinyin: "Zhèr méiyǒu niǎo.",
            hanzi: "这儿没有鸟。",
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-38/flamingo-1.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-38/flamingo-2-meiyou.svg`),
            Showing:false,
          },
          {
            pinyin: "Zhèr méiyǒu niǎo.",
            hanzi: "这儿没有鸟。",
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-38/flamingo-1.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-38/flamingo-2-meiyou.svg`),
            Showing:false,
          },
        ],
        [
          {
            pinyin: "Zhèr méiyǒu māo.",
            hanzi: "这儿没有猫。",
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-38/cat-1.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-38/cat-2-meiyou.svg`),
            Showing:false,
          },
          {
            pinyin: "Zhèr yǒu māo.",
            hanzi: "这儿有猫。",
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-38/cat-1.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-38/cat-2-you.svg`),
            Showing:false,
          },
          {
            pinyin: "Zhèr méiyǒu māo.",
            hanzi: "这儿没有猫。",
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-38/cat-1.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-38/cat-2-meiyou.svg`),
            Showing:false,
          },
      ],
      [
        {
          pinyin: "Zhèr méiyǒu gǒu.",
          hanzi: "这儿没有狗。",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-38/dog-1.svg`),
          imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-38/dog-2-meiyou.svg`),
          Showing:false,
        },
        {
          pinyin: "Zhèr méiyǒu gǒu.",
          hanzi: "这儿没有狗。",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-38/dog-1.svg`),
          imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-38/dog-2-meiyou.svg`),
          Showing:false,
        },
        {
          pinyin: "Zhèr yǒu gǒu.",
          hanzi: "这儿有狗。",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-38/dog-1.svg`),
          imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-38/dog-2-you.svg`),
          Showing:false,
        },
      ],
      ]
    };
  },
  methods: {
    changeTheImg(index, currentIndex) {
      this.wordCardList[currentIndex][index].Showing = !this.wordCardList[currentIndex][index].Showing
    },
  },
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
