var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wordscard-container layout-border" },
    [
      _c("WordImgGroupCards", {
        attrs: {
          wordCardList: _vm.wordCardList,
          titleInfo: _vm.titleInfo,
          buttonList: _vm.buttonList,
        },
        on: { changeTheImg: _vm.changeTheImg },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }