<template>
  <div class="game-container layout-border">
    <FindStroke
      :bgImg="bgImg"
      :gameIndex="gameIndex"
      :strokeImgList="strokeImgList"
      :strockNotFoundImage="strockNotFoundImage"
      :strockFoundImage="strockFoundImage"
    />
  </div>
</template>

<script>
import FindStroke from "@/components/Course/GamePage/FindStrokeGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-38/background-dragonboat.svg"),
      gameIndex:38,
      strokeImgList: [
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-38/stroke-boat.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-38/stroke-boat-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-38/stroke-dragon.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-38/stroke-dragon-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-38/stroke-tip.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-38/stroke-tip-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-38/stroke-tree.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-38/stroke-tree-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-38/stroke-zongzi.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-38/stroke-zongzi-found.svg"),
        },
      ],
      strockNotFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-38/stroke-ti-pending.svg"),
      strockFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-38/stroke-ti-found.svg"),
    };
  },
  components: {
    FindStroke,
  },
};
</script>

<style lang="scss" scoped>
</style>
