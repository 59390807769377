<!--
 * @Author: your name
 * @Date: 2022-03-14 15:48:19
 * @LastEditTime: 2022-03-15 09:54:10
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter3\Lesson20\13_writePage_piezhe.vue
-->
<template>
  <div class="write-page-heng-container">
    <WriteStrokePage
      :strokeImage="strokeImg"
      :backgroundImageList="bgImgList"
      :flagImageObj="flagImageObj"
      :strokeImgFill="strokeImgFill"
      :strokePinyin="strokePinyin"
    />
  </div>
</template>
<script>
import WriteStrokePage from "@/components/Course/CoursePage/WriteStrokePage";

export default {
  data() {
    return {
      strokePinyin: "tí",
      strokeImg: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-38-ti/stroke-ti-grey.svg"),
      strokeImgFill: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-38-ti/stroke-ti-red.svg"),
      bgImgList: [
        {
          step: 0,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-38-ti/trash-1.svg")
        },
        {
          step: 1,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-38-ti/trash-2.svg")
        },
        {
          step: 2,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-38-ti/trash-3.svg")
        },
        {
          step: 3,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-38-ti/trash-4.svg")
        }
      ],
      flagImageObj: {
        img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-38-ti/trash-pending.svg"),
        imgActive: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-38-ti/trash-complete.svg"),
        pinyin:'tí',
        tipImg:require('@/assets/img/16-GAMES/G19-writing-strokes/lesson-38-stroke-ti.gif')
        
      },
    };
  },
  components: {
    WriteStrokePage,
  },
};
</script>
<style lang="scss" scoped>
.write-page-heng-container {
  width: 100%;
  height: 100%;
}
</style>
